import * as React from 'react';
import * as Rebass from 'rebass';
import { Layout } from '../../../components/layout';

export const CareersPage = () => (
  <Layout>
    <Rebass.Text>Careers</Rebass.Text>
  </Layout>
)

export default CareersPage;